<template>
	<div>
		<Loader />
		<Menu />
		<div class="backdrop"></div>
		<main class="flex-shrink-0 main has-footer">
			<Header />
			<div class="container mb-1 mt-3 text-center text-white">
				<h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">restaurant</span> {{$t('restaurante')}}</h5>
				<h6>{{$t('restaurantetitulo')}}</h6>	
			</div>
			<div class="main-container">
				<!-- Menu Digital -->
				<div v-if="this.$store.getters.GetLoginInfo.hotel.sitemenudigital" class="container mb-3">
					<div class="card bg-default ponteiro"
						@click="openSite()"
					>
						<div class="card-body">
							<div class="media">
								<div class="icon icon-50 bg-white text-default mr-2 rounded-circle"><i class="material-icons">menu_book</i></div>
								<div class="media-inner">
									<h5 class="font-weight-normal">{{$t('acessarmenu')}}</h5>
									<p><small class="text-mute">{{$t('menupedido')}}</small></p>
								</div>
								<div class="align-self-center ml-auto">
									<i class="material-icons">arrow_forward</i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Café da Manhã -->
				<div class="container" v-if="showCafe">
					<div class="card border-0 mb-3">
						<div class="card-body ponteiro">
							<a @click="$router.push('/cafe')">
								<div class="row align-items-center">
									<div class="col-auto pr-0">
										<div class="avatar avatar-50 border-0 bg-success-light rounded-circle text-success">
											<i class="material-icons vm text-template">local_cafe</i>
										</div>
									</div>
									<div class="col-auto">
										<h6 class="mb-1">{{$t('solicitarcafedamanha')}}</h6>
										<p class="small text-secondary">{{$t('cafedamanhapedido')}}</p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<!-- Reservas -->
				<!-- <div class="container mb-4">
					<div class="card border-0 mb-3">
						<div class="card-body">
							<div class="row align-items-center">
								<div class="col-auto pr-0">
									<div class="avatar avatar-50 border-0 bg-info rounded-circle text-white">
										<i class="material-icons vm text-template">book_online</i>
									</div>
								</div>
								<div class="col-auto align-self-center">
									<h6 class="mb-1">{{$t('reservademesa')}}</h6>
											<p class="small text-secondary">{{$t('mesapedido')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- Reposição de Frigobar -->
				<!-- <div class="container mb-4">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col">
									<h6 class="mb-1">{{$t('reporfrigobar')}}:</h6>
									<ul class="list-group list-group-flush">
										<li class="list-group-item">
											<div class="custom-control custom-switch">
												<input  type="checkbox" name="horario" class="custom-control-input" id="customSwitch1" :checked="reporfrigobar" v-model="reporfrigobar">
												<label class="custom-control-label" for="customSwitch1">{{$t('frigobartitulo')}}</label>
											</div>
										</li>
										<li class="list-group-item">
											<label class="form-control-label">{{$t('periodo')}}:</label>
										</li>
									</ul>
									<div class="row mb-4 mr-2 ml-2">
										<div class="col-6">
											{{$t('de')}}:<input v-model="de" type="time" class="form-control" autofocus="">
										</div>
										<div class="col-6">
											{{$t('ateas')}}<input v-model="ate" type="time" class="form-control" autofocus="">
										</div>
									</div>
									<div class="row mb-4 mr-2 ml-2">
										<div class="col-12 px-0">
											<label class="form-control-label">{{$t('selecioneositens')}}:</label>
											<produtos-grupos :grupos="gruposprodutos"></produtos-grupos>
										</div>
									</div>
									<div class="row mb-4 mr-2 ml-2">
										<div class="col-12">
											<label class="form-control-label">{{$t('informacaoadicional')}}:</label>
											<textarea v-model="infoAdicional" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
										</div>
									</div>
									<button :disabled="!de&&!ate&&!infoAdicional.trim()" @click="reporFrigobar(1)" class="btn btn-block btn-default rounded mr-3 mt-3">{{$t('confirmarsolicitacao')}}</button>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- Outros Itens -->
				<!-- <div class="container mb-4">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col">
									<h6 class="mb-1">{{$t('solicitaroutrositens')}}:</h6>
									<ul class="list-group list-group-flush">
										<li class="list-group-item mb-2 mt-3">
											<label>{{$t('talheres')}}:</label>
											<div class="input-group cart-count cart-count-lg rounded mt-0 mb-2 ml-3">
												<div class="input-group-prepend">
													<button @click="talheres>=1?talheres--:0" class="btn btn-outline-secondary rounded" type="button">-</button>
												</div>
												<input type="text" class="form-control rounded" placeholder="" :value="talheres">
												<div class="input-group-append">
													<button @click="talheres++" class="btn btn-outline-secondary rounded" type="button">+</button>
												</div>
											</div>
										</li>
										<li class="list-group-item mb-2">
											<label>{{$t('copos')}}:</label>
											<div class="input-group cart-count cart-count-lg rounded mt-0 mb-2 ml-3">
												<div class="input-group-prepend">
													<button @click="copos>=1?copos--:0" class="btn btn-outline-secondary rounded" type="button">-</button>
												</div>
												<input type="text" class="form-control rounded" placeholder="" :value="copos">
												<div class="input-group-append">
													<button @click="copos++" class="btn btn-outline-secondary rounded" type="button">+</button>
												</div>
											</div>
										</li>
										<li class="list-group-item mb-4">
											<label>{{$t('tacas')}}:</label>
											<div class="input-group cart-count cart-count-lg rounded mt-0 mb-2 ml-3">
												<div class="input-group-prepend">
													<button @click="tacas>=1?tacas--:0" class="btn btn-outline-secondary rounded" type="button">-</button>
												</div>
												<input type="text" class="form-control rounded" placeholder="" :value="tacas">
												<div class="input-group-append">
													<button @click="tacas++" class="btn btn-outline-secondary rounded" type="button">+</button>
												</div>
											</div>
										</li>
										<li class="list-group-item mb-4">
											<label>{{$t('pratos')}}:</label>
											<div class="input-group cart-count cart-count-lg rounded mt-0 mb-2 ml-3">
												<div class="input-group-prepend">
													<button @click="pratos>=1?pratos--:0" class="btn btn-outline-secondary rounded" type="button">-</button>
												</div>
												<input type="text" class="form-control rounded" placeholder="" :value="pratos">
												<div class="input-group-append">
													<button @click="pratos++" class="btn btn-outline-secondary rounded" type="button">+</button>
												</div>
											</div>
										</li>
										<li class="list-group-item mb-4">
											<label>{{$t('abridordegarrafa')}}:</label>
											<div class="input-group cart-count cart-count-lg rounded mt-0 mb-2 ml-3">
												<div class="input-group-prepend">
													<button @click="abridordegarrafa==1?abridordegarrafa--:0" class="btn btn-outline-secondary rounded" type="button">-</button>
												</div>
												<input type="text" class="form-control rounded" placeholder="" :value="abridordegarrafa">
												<div class="input-group-append">
													<button @click="abridordegarrafa==0?abridordegarrafa++:1" class="btn btn-outline-secondary rounded" type="button">+</button>
												</div>
											</div>
										</li>
										<li class="list-group-item">
											<button :disabled="talheres==0&&copos==0&&tacas==0&&pratos==0&&abridordegarrafa==0" @click="reporFrigobar(2)" class="btn btn-block btn-default rounded mr-3">{{$t('confirmarsolicitacao')}}</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- Itens do Restaurante exceto Café -->
				<div class="container mb-2">
					<sol-control :setor="1" :tiposNot="'10'"></sol-control>
				</div>
			</div>
		</main>
		<Footer />
	</div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import SolControl from './SolControl.vue'

export default {
    components: {
        Loader, Menu, Header, Footer, SolControl
    },  
    data() {
        return {
			reporfrigobar: true,
			de:'',
			ate:'',
			headers: {
				'x-access-token': this.$store.getters.GetLoginInfo.token
			},
			infoAdicional: '',
			talheres: 0,
			copos: 0,
			tacas: 0,
			pratos: 0,
			abridordegarrafa: 0,
			gruposprodutos: [],
			showCafe: true
        }
	},
	methods: {
		openSite() {
			var url = this.$store.getters.GetLoginInfo.hotel.sitemenudigital
			if (url) {
				url = new String(url)
				if (url.substring(0,4) != 'http') {
					url = 'http://' + url
				}
				window.open(url,'')
			}
		},
        checkToShowCafe() {
            var params = {
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "idhotlinesetores" : 1,
                "itensnulos": false,
                "ativo": true,
				"tipos": '10',
                "interna": false
            };
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
					console.log('data.length:'+response.data.length)
					this.showCafe = response.data.length > 0
                }
            })
        },
		carregarGruposProdutos() {
			var params = {
				idhotel :  this.$store.getters.GetLoginInfo.idhotel,
				incluirprodutos: true,
				ativo: true,
				legado: false
			}
            axios.post(CONSTANTS.API_URL + '/fdguest/produto/grupo/select',
            params, {headers: this.headers}).then(response => {
                if(response.status == 200) {
					this.gruposprodutos = response.data
                }
            })
		},
		reporFrigobar(subtipo) {
            var str = this.$store.getters.GetLoginInfo.nome;
			var texto = ''
			if (subtipo==1) {
				texto = `${this.$t('porfavorreposicaofrigobar') + this.$t('horarioentre')}`  + this.de 
					+  ` ${this.$t('e')} ` 
					+ this.ate + `${this.infoAdicional?'. Informação adicional: '+this.infoAdicional:''}`
			} else {
				texto = `${this.$t('porfavorreposicaofrigobar')}: `
					+ `${this.talheres > 0?this.$t('talheres') + '(' + this.talheres + '); ':''}`
					+ `${this.copos > 0?this.$t('copos') + '(' + this.copos + '); ':''}`
					+ `${this.tacas > 0?this.$t('tacas') + '(' + this.tacas + '); ':''}`
					+ `${this.pratos > 0?this.$t('pratos') + '(' + this.pratos + '); ':''}`
					+ `${this.abridordegarrafa > 0?this.$t('abridordegarrafa')+'.':''}`
			}
            var res = str.split(" ");

            var nome = res[0];

            var sobrenome = (res.length==1)?'':res[res.length-1];

            var frigobar = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" :  this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
				"idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : texto,
                "idtiposolicitacao" : CONSTANTS.TIPO.FRIGOBAR
            };
           
           
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            frigobar, {headers: this.headers}).then(response => {
                if(response.status == 200) {
					this.$router.push("/sucesso")
                }
            })
        },
	},
	created() {
		this.checkToShowCafe();
	},
	mounted() {
		//this.carregarGruposProdutos()
	}
		

}
</script>

<style>

</style>