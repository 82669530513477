<template>
    <div>
        <Loader />
        <Menu />
        <Header />
        <div class="backdrop"></div>
        <main class="flex-shrink-0 main has-footer">
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">handyman</span> {{$t('manutencao')}}</h5>
                <p class="mb-4">{{$t('manutencaotitulo')}}</p>
            </div>
            <div class="main-container">
                <!-- Ítens Cadastrados -->
                <div class="col-12 mb-2">
                    <sol-control :setor="4"></sol-control>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import SolControl from './SolControl.vue'
export default {
    components: {
         Loader, Menu, Header, Footer,
        SolControl
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
        }
    }
}
</script>