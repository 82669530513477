<template>
  <div>
    <Loader />
    <Menu />
    <div class="backdrop"></div>
    <main class="flex-shrink-0 main has-footer">
      <Header />
      <!-- Título -->
      <div class="container mb-1 mt-3 text-center text-white">
        <h5 class="my-3 es-titulo">
          <span class="material-icons icon my-3 es-titulo">face</span>
          {{ $t("concierge") }}
        </h5>
        <p class="">{{ $t("consultetelefonesempresasparceiras") }}</p>
        <!-- Atenção -->
        <div class="text-center atencao mb-4">
          <span>{{ $t("atencaoservicos") }}</span>
        </div>
      </div>
      <!-- Subtítulo -->
      <div
        class="container mt-2 mb-4 text-center text-white"
        v-if="parceiros && parceiros.length == 0"
      >
        <h5>{{ $t("semempresasparceiras") }}</h5>
      </div>
      <!-- Filtro de Busca -->
      <div class="container">
        <!-- Texto de Pesquisa -->
        <div class="form-group">
          <input
            v-on:keyup="searchPasseios()"
            type="text"
            class="form-control"
            placeholder="Pesquisar por nome"
            v-model="nomeEmpresa"
          />
        </div>
        <!-- Slider -->
        <Slider
          :inView="4"
          type="html"
          v-if="categorias.length > 0"
          class="mb-3"
        >
          <div
            class="card-slider"
            v-for="(categoria, idx) in categorias"
            :key="idx"
          >
            <div :id="'filtro' + categoria.idcategoria" class="card-body">
              <div
                class="
                  avatar avatar-60
                  rounded
                  mb-1
                  bg-default-light
                  text-default
                "
              >
                <span
                  @click="searchInfo(categoria.idcategoria)"
                  class="material-icons"
                  >{{ categoria.icone }}</span
                >
              </div>
              <p class="text-secondary">
                <small>{{ categoria.descricao }}</small>
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <!-- Resultado da Busca -->
      <div v-if="parceiros && parceiros.length > 0">
        <div class="main-container">
          <div class="container mb-2">
            <div class="row">
              <div
                class="col-12 col-md-6 mt-3"
                v-for="(parceiro, idx) in parceiros"
                :key="idx"
              >
                <div class="card border-0">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col align-self-center pr-0">
                        <h6 class="mb-0">{{ parceiro.nome }}</h6>
                        <p class="text-secondary">
                          <a>{{ parceiro.telefone }}</a>
                        </p>
                      </div>
                      <div class="col-auto align-self-center pl-0">
                        <a
                          :href="'tel:' + parceiro.telefone"
                          class="btn btn-sm btn-default rounded mr-1"
                          ><img src="../../img/phone-call.svg" width="18"
                        /></a>
                        <a
                          :href="'https://wa.me/' + parceiro.whatsapp"
                          class="btn btn-sm btn-success rounded"
                          ><img src="../../img/whatsapp.svg" width="18" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <address>
                      Endereço:<br />
                      {{ parceiro.endereco }}, {{ parceiro.numero }}<br />
                      {{ parceiro.cidade }}, {{ parceiro.ufnome }}
                    </address>
                    <br />
                    <div class="row">
                      <div class="btn btn-sm btn-info mx-2 mt-3 mb2"
                        @click="openMaps(parceiro)"
                        ><img src="../../img/map.svg" width="18" />
                        {{ $t("mapa") }}</div
                      >
                      <div class="btn btn-sm btn-primary mx-2 mt-3 mb2" @click="abrirPromocoes(parceiro)"
                        >
                        <i class="material-icons vm text-white">local_offer</i>
                        {{ $t("promocoes") }}</div
                      >                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Texto quando não há resultado da busca -->
      <div v-if="parceiros && parceiros.length == 0">
        <div class="main-container">
          <div class="container mb-2">
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <div class="card border-0">
                  <div class="container mt-2 mb-4 text-center atencao">
                    <h5>{{ $t("semempresasfiltro") }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import Menu from "../components/Menu.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import CONSTANTS from "../constants";
import axios from "axios";
import Slider from "../components/Slider.vue";
export default {
  components: {
    Loader,
    Menu,
    Header,
    Footer,
    Slider,
  },
  data() {
    return {
      parceiros: [],
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      passeiosParams: [],
      passeiosItems: [],
      categorias: [],
      nomeEmpresa: "",
      parceirosfilter: [],
    };
  },
  methods: {
    openMaps(p){
        var link=document.createElement("a");
        link.id = 'mapsLink';
        var addr = `${p.endereco},`
        addr += `${p.numero},`
        addr += `${p.bairro},`
        addr += `${p.cidade},`
        addr += `${p.ufsigla},`
        addr += `${p.pais}`
        addr = encodeURI(addr.trim())
        link.href=`https://maps.google.com/?q=${addr}`;
        link.style = "visibility:hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },    
    abrirPromocoes(p) {
      this.$store.dispatch('set_IdParceiro', p.idparceiro);
      this.$router.push('/promocaoparceiro')
    },
    searchPasseios() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/parceiro/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
            nome: this.nomeEmpresa,
            ativo: true,
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.parceiros = response.data;
            console.log(this.parceiros);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    searchInfo(idcategoria) {
      var param = {
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
        idcategoria: idcategoria,
        idioma: this.$i18n.locale,
        ativo: true,
      };
      for (var i = 0; i < this.categorias.length; i++) {
        var cat = this.categorias[i];
        var m = document.getElementById("filtro" + cat.idcategoria).style;
        if (cat.idcategoria != idcategoria) {
          m.opacity = 1;
        }
      }
      var s = document.getElementById("filtro" + idcategoria).style;
      if (s.opacity == 0.5) {
        s.opacity = 1;
        delete param.idcategoria;
      } else {
        s.opacity = 0.5;
      }

      axios
        .post(CONSTANTS.API_URL + "/fdguest/parceiro/select", param, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.parceiros = response.data;
            console.log(this.parceiros);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },

    searchCategorias() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/categoria/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.categorias = response.data;
            console.log(this.categorias);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
  },
  mounted() {
    this.searchPasseios();
    this.searchCategorias();
  },
};
</script>

<style scoped>
.swiper-wrapper {
  overflow: auto;
  white-space: nowrap;
}
.swiper-slide {
  display: inline-block;
}
.atencao {
  color: orange !important;
  /* background-color: azure; */
  text-align: center;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
}
</style>