<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
        <Header @atualizarNotificacoes="[loadNotificacoes()]"/>
        <div class="container mb-1 mt-3 text-center text-white">
            <h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">receipt_long</span> {{$t('notificacoes')}}</h5>
            <h6>{{$t('acompanhetodasassuasnofificacoes')}}</h6>	
        </div>
          <div class="main-container border-success">
            <div class="container">
                <div class="card">
                    <div class="card-body px-0">
                        <div class="list-group list-group-flush">
                            <template v-if="notas&&notas.length>0">
                                <div v-for="(nota,index) in notas" v-bind:key="index"
                                    :class="getClass(nota)" class="my-2" @click="exibirDetalhe(nota)"
                                >
                                    <div class="row">
                                        <div class="col-auto align-self-center">
                                            <i class="material-icons text-default">{{nota.status}}</i>
                                        </div>
                                        <div class="col pl-0">
                                            <div class="row mb-1">
                                                <div class="col text-info">
                                                    <p class="mb-0">{{nota.assunto}}</p>
                                                </div>
                                                <div class="col-auto pl-0">
                                                    <p class="small text-secondary">{{formatarData(nota.datanotificacao)}}</p>
                                                </div>
                                            </div>
                                            <p class="small text-primary es-timeline-text">{{nota.texto}}</p>
                                        </div>
                                        <!-- <div class="col-md-12" v-if="nota.produtos&&nota.produtos.length>0">
                                            <solicitacao-produto-historico
                                                :produtos="nota.produtos" :idevento="nota.idevento"
                                            ></solicitacao-produto-historico>
                                        </div> -->
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
      </main>
      <Footer />
  </div>
</template>

<script>
    import axios from 'axios'
    import CONSTANTS from '../constants'
    import Loader from '../components/Loader.vue'
    import Menu from '../components/Menu.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    import moment from "moment"

    export default {
        components: {
            Loader, Menu, Header, Footer
        },
        data() {
            return {
                notas: null,
                headers: {
                        'x-access-token': this.$store.getters.GetLoginInfo.token
                }
            }
        },
        methods: {
            formatarData(aDate) {
                //return moment(aDate).format('DD-MM-YY HH:MM');
                return moment(aDate).locale(this.$i18n.locale).format('LLL');
            },        
            exibirDetalhe(nota) {
                this.$store.dispatch('set_Nota_Atual', nota);
                this.$router.push('/notificacoesdetalhe')
                
            },
            getClass(nota) {
                if (nota.status == 'mail')
                    return 'list-group-item bg-default-light ponteiro rounded es-shadow'
                else
                    return 'list-group-item ponteiro rounded es-shadow'
            },
            carregarNotas() {
                var params = {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    para: this.$store.getters.GetLoginInfo.idpessoa,
                    tipo: 'H'
                };
                //console.log(JSON.stringify(params));
                axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', params, {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.notas = response.data;
                        }
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });                
            },
            loadNotificacoes() {
                axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', {
                        idhotel: this.$store.getters.GetLoginInfo.idhotel,
                        para: this.$store.getters.GetLoginInfo.idpessoa,
                        tipo: 'H',
                        whereRaw: 'notificacao.datavisualizacao is null',
                        idioma: this.$i18n.locale
                    }, {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.$store.dispatch('set_Notificacoes', response.data);
                            var obj = document.getElementById('pulse')
                            if (obj) {
                                if (response.data.length == 0) {
                                    if (obj.classList.contains('pulse-css')) {
                                        obj.classList.remove('pulse-css')
                                    }
                                } else {
                                    if (!obj.classList.contains('pulse-css')) {
                                        obj.classList.add('pulse-css')
                                    }
                                }
                            }
                        }
                    } else {
                        console.log(response.message)
                    }
                })
                .catch((err) => {
                    if (err.message.includes('403')) {
                        console.log('Erro de autenticação! Direcionar para login...');
                        sessionStorage.clear();
                        clearInterval(this.intervalId);
                        this.$router.push('/');
                    } else {
                        console.log(JSON.stringify(err))
                    }
                });
            }
        },
        mounted() {
            this.loadNotificacoes();
            this.carregarNotas();
        }

}
</script>

<style>
.es-shadow {
    border: 2px indigo!important;
    box-shadow: 5px 5px 5px indigo;
}
</style>