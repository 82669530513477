<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
          <div class="container mb-1 mt-3 text-center text-white">
            <h5 class="my-3 es-titulo"><span class="material-icons icon es-titulo">hotel</span> {{$t('governanca')}}</h5>
			<p class="mb-4">{{$t('governancatitulo')}}</p>
        </div>
        <div class="main-container">
            <div class="container mb-2">
                <div class="row">
                    <!-- Serviço de Quarto e Não Perturbe -->
                    <div class="col-12 col-md-6 mt-3">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="mb-1">{{$t('servicoquarto')}}</h6>
                                <p>{{$t('servicoquartoitem')}}</p>
                                <div class="row mt-2">
                                    <div class="col-6 col-md-auto">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" name="menustyle" class="custom-control-input" id="menu-overlay" :checked="naopertube" @click="changeStatusN(naopertube)" >
                                            <label class="custom-control-label" for="menu-overlay">{{$t('naopertube')}}</label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-auto">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" name="menustyle" class="custom-control-input" id="menu-pushcontent" :checked="limparquarto" @click="changeStatusL(limparquarto)">
                                            <label class="custom-control-label" for="menu-pushcontent">{{$t('limparquarto')}}</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-auto mt-3">
                                        <div class="row mb-4">
                                            <div class="col-6">
                                                &nbsp; {{$t('de')}}:<input type="time" class="form-control" autofocus="" v-model="horariode">
                                            </div>
                                            <div class="col-6">
                                                &nbsp; {{$t('ate')}}<input type="time" class="form-control" autofocus="" v-model="horarioate">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-auto" v-if="limparquarto">
                                        <div class="col-12">
                                            <label class="form-control-label">{{$t('informacaoadicional')}}:</label>
                                            <textarea v-model="infoServicoQuarto" class="form-control" id="exampleFormControlTextarea2" rows="3"></textarea>
                                        </div>
                                    </div>
                                    <template v-if="!validTimes || !validNaoPerturbe">
                                        <div class="horario-total">
                                            <div class="fora-horario">
                                                Fora do horário permitido.
                                            </div>
                                            <div class="horario-permitido" v-if="!validTimes">
                                                Escolha entre {{this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial}} e {{this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal}}
                                            </div>
                                        </div>
                                    </template>
                                    <button :disabled="!validTimes||!validNaoPerturbe" @click="solServicoQuarto()" class="btn btn-block btn-default rounded mr-3 ml-3 mt-3">{{$t('confirmarsolicitacao')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Ítens Cadastrados -->
                    <sol-control :setor="5"></sol-control>
                </div>
            </div>
        </div>
      </main>
      <Footer />
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
import SolControl from './SolControl.vue'
export default {
    components: {
         Loader, Menu, Header, Footer,
        SolControl
    },
    data() {
        return {
            naopertube: true,
            limparquarto: false,
            infoServicoQuarto: '',
            infoRoupaCama:'',
            servicoquarto: '',
            horariode: this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial,
            horarioate: this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal,
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tiposSolicitacao: []
        }
    },
    computed: {
        validNaoPerturbe() {
            var horarioInicial = this.horariode?this.horariode:'11:00';
            var horarioFinal = this.horarioate?this.horarioate:'16:00';
            var aStr = horarioInicial.replace(/[:]/g, '')
            var a = parseInt(aStr)
            var bStr = horarioFinal.replace(/[:]/g, '')
            var b = parseInt(bStr)
            if (this.naopertube) {
                return (a<=b);
            } else {
                return true;
            }
        },
		validTimes() {
            if (this.limparquarto) {
                var horarioInicial = this.horariode?this.horariode:'11:00';
                var horarioFinal = this.horarioate?this.horarioate:'16:00';
                var limiteInferior = parseInt(this.$store.getters.GetLoginInfo.hotel.limpezahorarioinicial.replace(/[:]/g, ''));
                var limiteSuperior = parseInt(this.$store.getters.GetLoginInfo.hotel.limpezahorariofinal.replace(/[:]/g, ''));
                var aStr = horarioInicial.replace(/[:]/g, '')
                var a = parseInt(aStr)
                var bStr = horarioFinal.replace(/[:]/g, '')
                var b = parseInt(bStr)
                return (a <= b && a >= limiteInferior && b <= limiteSuperior);
            } else {
                return true;
            }
		}
    },
    methods: {
        changeStatusL() {
            this.limparquarto = true;
            this.naopertube = false;
        },
        changeStatusN() {
            this.limparquarto = false;
            this.naopertube = true;
        },
        solServicoQuarto() {
            var str = this.$store.getters.GetLoginInfo.nome;

            var res = str.split(" ");

            var nome = res[0];

            var sobrenome = (res.length==1)?'':res[res.length-1];

            if (this.limparquarto) {
                this.servicoquarto = `${this.$t('favorlimpar')}` + this.horariode + ` ${this.$t('e')} ` + this.horarioate
            } else {
                this.servicoquarto = `${this.$t('favornaopertubar')}` + this.horariode + ` ${this.$t('e')} ` + this.horarioate
            }
            if (this.infoServicoQuarto.trim() != '') {
                this.servicoquarto += `\n${this.$t('informacaoadicional')}: ${this.infoServicoQuarto}`
            }
            var servicodequarto = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : this.servicoquarto,
				"idtiposolicitacao" : CONSTANTS.TIPO.ARRUMAR
            };
           
           console.log(JSON.stringify(servicodequarto))
           
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            servicodequarto, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.$router.push("/sucesso")
                }
            })
        }
    }
}
</script>

<style>
.horario-total {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    background-color: white!important;
    width: 100%;
}
.horario-permitido {
    padding: 0.5rem;
    border-radius: 0.7rem;
    font-weight: bold;
    color: green!important;
    background-color: white!important;
    width: 50%;
}
.fora-horario{
    padding: 0.5rem;
    border-radius: 0.7rem;
    font-weight: bold;
    color: orange;
    background-color: white!important;
    width: 50%;
}
.es-titulo {
    font-size: 1.8rem;
}
</style>