<template>
    <div>
        <Loader />
        <Menu />
        <main class="flex-shrink-0 main ">
            <Header />
            <div class="row" @click="$router.push('/notificacoes')">
                <div class="container mb-1 mt-3 text-center text-white ponteiro">
                    <h5 class="mb-4"><span class="material-icons icon">keyboard_arrow_left</span> {{$t('voltar')}}</h5>
                </div>
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="card">
                        <div class="card-header border-bottom">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="icon icon-40 rounded bg-default-light"><i class="material-icons text-default">done_all</i></div>
                                </div>
                                <div class="col pl-0 align-self-center">
                                    <h6 class="mb-1">{{nota.assunto}}</h6>
                                    <p class="text-secondary "><small class="float-right text-secondary">{{formatarData(nota.datanotificacao)}}</small></p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p v-if="nota.textohotlinesolicitacoes" class="es-text-sol"><strong>"{{nota.textohotlinesolicitacoes}}"</strong></p>
                            <template v-if="nota.link">
                                <a class="navbar-brand" :href="nota.link">
                                    <!-- <h5 class="mb-0">{{nota.texto}}</h5> -->
                                    <h5 class="btn btn-block btn-default rounded mr-3">{{nota.texto}}</h5>
                                </a>
                            </template>
                            <template v-else>
                                <p class="es-timeline-text">{{nota.texto}}</p>
                            </template>
                            <!-- <div class="col-md-12" v-if="nota.produtos&&nota.produtos.length>0">
                                <solicitacao-produto-historico
                                    :produtos="nota.produtos" :idevento="nota.idevento"
                                ></solicitacao-produto-historico>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
  </div>
</template>

<script>
    import axios from 'axios'
    import CONSTANTS from '../constants'
    import Loader from '../components/Loader.vue'
    import Menu from '../components/Menu.vue'
    import Header from '../components/Header.vue'
    import Footer from '../components/Footer.vue'
    import moment from "moment"
//import SolicitacaoProdutoHistorico from './SolicitacaoProdutoHistorico.vue'
    export default {
        components: {
            Loader, Menu, Header, Footer,
                // SolicitacaoProdutoHistorico
        },
        data() {
            return {
                nota: this.$store.getters.GetNotaAtual,
                header: {
                        'x-access-token': this.$store.getters.GetLoginInfo.token
                }
            }
        },
        methods: {
            formatarData(aDate) {
                //return moment(aDate).format('DD-MM-YY HH:MM');
                return moment(aDate).locale(this.$i18n.locale).format('LLL');
            },
            async marcarNotaVisualizada() {
                var params = {
                    "idnotificacao" : this.nota.idnotificacao,
                    "datavisualizacao" : moment().format()
                };
                await axios.put(CONSTANTS.API_URL + '/fdguest/notificacao/update', params, {headers: this.header})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            // Nota visualizada
                        }
                    }
                }).catch((err) => {
                    console.log({ err_menu: err });
                });                
            },
            loadNotificacoes() {
                axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', {
                        idhotel: this.$store.getters.GetLoginInfo.idhotel,
                        para: this.$store.getters.GetLoginInfo.idpessoa,
                        tipo: 'H',
                        whereRaw: 'notificacao.datavisualizacao is null',
                        idioma: this.$i18n.locale
                    }, {headers: this.headers})
                .then(response => {
                    if (response.status == 200) {
                        if (response.data) {
                            this.$store.dispatch('set_Notificacoes', response.data);
                            var obj = document.getElementById('pulse')
                            if (obj) {
                                if (response.data.length == 0) {
                                    if (obj.classList.contains('pulse-css')) {
                                        obj.classList.remove('pulse-css')
                                    }
                                } else {
                                    if (!obj.classList.contains('pulse-css')) {
                                        obj.classList.add('pulse-css')
                                    }
                                }
                            }
                        }
                    } else {
                        console.log(response.message)
                    }
                })
                .catch((err) => {
                    if (err.message.includes('403')) {
                        console.log('Erro de autenticação! Direcionar para login...');
                        sessionStorage.clear();
                        clearInterval(this.intervalId);
                        this.$router.push('/');
                    } else {
                        console.log(JSON.stringify(err))
                    }
                });
            }
        },
        mounted() {
            this.marcarNotaVisualizada();
            //this.loadNotificacoes();
        }

    }
</script>

<style>
.es-text-sol {
    text-align: center;
    
}
</style>