<template>
  <div>
    <Loader />
    <Menu />
    <div class="backdrop"></div>
    <main class="flex-shrink-0 main has-footer">
      <Header />
      <div class="container mb-1 mt-3 text-center text-white">
        <h5 class="my-3 es-titulo">
          <span class="material-icons icon my-3 es-titulo">spa</span>
          {{ $t("salaospa") }}
        </h5>
        <h6>{{ $t("salaotitulo") }}</h6>
      </div>
      <div class="container">
        <div class="form-group">
          <input
            v-on:keyup="searchServicos()"
            v-model="nomeServico"
            type="text"
            class="form-control"
            placeholder="Pesquisar por serviço"
          />
        </div>
        <!-- <div class="swiper-container swiper-users text-center mb-3 mt-5">
          <p class="text-white">{{ $t("selecioneitensfiltrar") }}:</p>
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(categoria, idx) in categorias"
              :key="idx"
            >
              <div class="card mr-1">
                <div
                  :id="'filtro' + categoria.idcategoria"
                  class="card-body p-2"
                >
                  <div
                    class="
                      avatar avatar-60
                      rounded
                      mb-1
                      bg-default-light
                      text-default
                    "
                  >
                    <span
                      @click="searchInfo(categoria.idcategoria)"
                      class="material-icons"
                      >{{ categoria.icone }}</span
                    >
                  </div>
                  <p class="text-secondary">
                    <small>{{ categoria.descricao }}</small>
                  </p>
                </div>
              </div>
            </div>
           
          </div>
        </div> -->
        <Slider
          :inView="3"
          type="html"
          v-if="categorias.length > 0"
          class="mb-3"
        >
          <div
            class="card-slider"
            v-for="(categoria, idx) in categorias"
            :key="idx"
          >
            <div :id="'filtro' + categoria.idcategoriaservico" class="card-body">
              <div
                class="
                  avatar avatar-60
                  rounded
                  mb-1
                  bg-default-light
                  text-default
                "
              >
                <span
                  @click="searchInfo(categoria.idcategoriaservico)"
                  class="material-icons"
                  >{{ categoria.icone }}</span
                >
              </div>
              <p class="text-secondary">
                <small>{{ categoria.descricao }}</small>
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div class="main-container">
        <div class="container mb-2">
          <div class="row">
            <!-- <div class="col-12 col-md-6 mt-3">
              <button class="btn btn-outline-default px-2 btn-block rounded">
                <span class="material-icons mr-1">phone</span> 99999-99999
              </button>
            </div> -->
            <div
              class="col-12 col-md-6 mt-3"
              v-for="(servico, idx) in servicos"
              :key="idx"
            >
              <div class="card border-0">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col align-self-center pr-0">
                      <h6 class="mb-0">{{ servico.titulo }}</h6>
                      <p class="text-secondary">
                        <a>{{ servico.subtitulo }}</a>
                      </p>
                    </div>
                    <div class="col-auto align-self-center pl-0">
                      <a
                        href="tel:08599999-9999"
                        class="btn btn-sm btn-default rounded"
                        >R$ {{ servico.valor }}</a
                      >
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  {{ servico.descricao }}
                </div>
              </div>
            </div>

            <!-- <div class="col-12 col-md-6 mt-3">
						<div class="card border-0">
							<div class="card-header">
								<div class="row align-items-center">
									<div class="col align-self-center  pr-0">
										<h6 class="mb-0">Corte de Cabelo</h6>
										<p class="text-secondary"><a>Corte Feminino</a></p>
									</div>
									<div class="col-auto align-self-center  pl-0">
										<a href="tel:08599999-9999" class="btn btn-sm btn-default rounded">R$ 100,00</a>
									</div>
								</div>
							</div>
							<div class="card-body">
								Informações detalhada do corte de cabelo
							</div>
						</div>
					</div>					
                    
					<div class="col-12 col-md-6 mt-3">
						<div class="card border-0">
							<div class="card-header">
								<div class="row align-items-center">
									<div class="col align-self-center  pr-0">
										<h6 class="mb-0">Corte de Cabelo</h6>
										<p class="text-secondary"><a>Corte Feminino</a></p>
									</div>
									<div class="col-auto align-self-center  pl-0">
										<a href="tel:08599999-9999" class="btn btn-sm btn-default rounded">R$ 100,00</a>
									</div>
								</div>
							</div>
							<div class="card-body">
								Informações detalhada do corte de cabelo
							</div>
						</div>
					</div>
					
					<div class="col-12 col-md-6 mt-3">
						<div class="card border-0">
							<div class="card-header">
								<div class="row align-items-center">
									<div class="col align-self-center  pr-0">
										<h6 class="mb-0">Corte de Cabelo</h6>
										<p class="text-secondary"><a>Corte Feminino</a></p>
									</div>
									<div class="col-auto align-self-center  pl-0">
										<a href="tel:08599999-9999" class="btn btn-sm btn-default rounded">R$ 100,00</a>
									</div>
								</div>
							</div>
							<div class="card-body">
								Informações detalhada do corte de cabelo
							</div>
						</div>
					</div> -->
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import Menu from "../components/Menu.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import CONSTANTS from "../constants";
import axios from "axios";
import Slider from "../components/Slider.vue";
export default {
  components: {
    Loader,
    Menu,
    Header,
    Footer,
    Slider,
  },
  data() {
    return {
      headers: { "x-access-token": this.$store.getters.GetLoginInfo.token },
      servicos: [],
      categorias: [],
      nomeServico: "",
    };
  },
  methods: {
    searchServicos() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/servico/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
            titulo: this.nomeServico,
            ativo: true
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.servicos = response.data;
            console.log(this.servicos);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    searchCategorias() {
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/categoriaservico/select",
          {
            idhotel: this.$store.getters.GetLoginInfo.idhotel,
            idioma: this.$i18n.locale,
          },
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.categorias = response.data;
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },

    searchInfo(idcategoria) {
      var param = {
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
        idcategoriaservico: idcategoria,
        idioma: this.$i18n.locale,
      };
      for (var i = 0; i < this.categorias.length; i++) {
        var cat = this.categorias[i];
        var m = document.getElementById("filtro" + cat.idcategoriaservico).style;
        if (cat.idcategoriaservico != idcategoria) {
          m.opacity = 1;
        }
      }
      var s = document.getElementById("filtro" + idcategoria).style;
      if (s.opacity == 0.5) {
        s.opacity = 1;
        delete param.idcategoriaservico;
      } else {
        s.opacity = 0.5;
      }

      axios
        .post(CONSTANTS.API_URL + "/fdguest/servico/select", param, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.servicos = response.data;
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
  },
  mounted() {
    this.searchServicos();
    this.searchCategorias();
  },
};
</script>

<style>
</style>