<template>
  <div>
      <Loader />
      <Menu />
      <div class="backdrop"></div>
      <main class="flex-shrink-0 main has-footer">
          <Header />
            <div class="container mb-1 mt-3 text-center text-white">
                <h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">assignment_ind</span> {{$t('gerencia')}}</h5>
                <p class="mb-4">{{$t('gerenciatitulo')}}</p>               
            </div>
            <div class="main-container">
                <div class="container mb-2">
                    <div class="row">
                        
                        <div class="container">
                            <div class="alert alert-success">
                               {{$t('suaopiniao')}} 
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    
                                    <div class="form-group float-label active">
                                        <select class="form-control" v-model="estrutura">
                                            <option selected="">{{$t('excelente')}}</option>
                                            <option selected="">{{$t('otimo')}}</option>
                                            <option selected="">{{$t('bom')}}</option>
                                            <option selected="">{{$t('regular')}}</option>
                                            <option selected="">{{$t('insatisfatorio')}}</option>
                                        </select>
                                                    <label class="form-control-label">{{$t('estruturahotel')}}</label>
                                    </div>
                                    
                                    <div class="form-group float-label active">
                                        <select class="form-control" v-model="atendimento">
                                             <option selected="">{{$t('excelente')}}</option>
                                            <option selected="">{{$t('otimo')}}</option>
                                            <option selected="">{{$t('bom')}}</option>
                                            <option selected="">{{$t('regular')}}</option>
                                            <option selected="">{{$t('insatisfatorio')}}</option>
                                        </select>
                                            <label class="form-control-label">{{$t('qualidadeatendimento')}}</label>
                                    </div>
                                    
                                    <div class="form-group float-label active">
                                        <select class="form-control" v-model="acomodacao">
                                            <option selected="">{{$t('excelente')}}</option>
                                            <option selected="">{{$t('otimo')}}</option>
                                            <option selected="">{{$t('bom')}}</option>
                                            <option selected="">{{$t('regular')}}</option>
                                            <option selected="">{{$t('insatisfatorio')}}</option>
                                        </select>
                                            <label class="form-control-label">{{$t('qualidadeacomodacao')}}</label>
                                    </div>
                                    
                                    <div class="form-group float-label">
                                        <textarea v-model="textoservicos" class="form-control" id="exampleFormControlTextarea" rows="3"></textarea>
                                        <label class="form-control-label">{{$t('descrevahotelservicos')}}</label>                          
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button @click="enviarOpiniao()" class="btn btn-block btn-default rounded">{{$t('enviar')}}</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>           
           
            </div>
      </main>
      <Footer />
  </div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    components: {
         Loader, Menu, Header, Footer
    },
    data() {
        return {
            estrutura: '',
            atendimento: '',
            acomodacao: '',
            textoservicos: '',
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                },
        }
    },
    methods: {
        enviarOpiniao() {
            var str = this.$store.getters.GetLoginInfo.nome;

            var res = str.split(" ");

            var nome = res[0];

            var sobrenome = (res.length==1)?'':res[res.length-1];
            var texto = ''
            if (this.estrutura)
                texto += `${this.$t('notaestrutura')}: ${this.estrutura}; `
            if (this.atendimento)
                texto += `${this.$t('notaatendimento')}: ${this.atendimento}; `
            if (this.acomodacao)
                texto += `${this.$t('notaacomodacao')}: ${this.acomodacao}; `
            if (this.textoservicos)
                texto += `${this.$t('observacoes')}: ${this.textoservicos}; `
            var opiniao = {
                "nome" : nome,
                "sobrenome" : sobrenome,
                "coduh" : this.$store.getters.GetLoginInfo.coduh,
                "idhotlinesetores" : this.$store.getters.GetCurrentItem.idhotlinesetores,
                "idhotel" : this.$store.getters.GetLoginInfo.idhotel,
                "numreserva" : this.$store.getters.GetLoginInfo.idreservasfront,
                "idhospedepms" : this.$store.getters.GetLoginInfo.idhospedepms,
                "idusuario" : "1",
                "textohotlinesolicitacoes" : texto,
				"idtiposolicitacao" : CONSTANTS.TIPO.OPINIAO
            };
          
           
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/insert',
            opiniao, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.$router.push("/sucesso")
                }
            })
        },
        limpar() {
                this.estrutura = '',
                this.atendimento = '',
                this.acomodacao = '',
                this.textoservicos = '';
        }

    }

}
</script>

<style>

</style>