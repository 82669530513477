<template>
   <header class="header">
        <div class="row linha" no-gutters>
            <div class="col-auto align-self-center px-0">
                <button id="menu-btn-id" class="menu-btn btn btn-40 btn-link turnoff" type="button">
                    <span class="material-icons">menu</span>
                </button>
            </div>
            <div class="text-left col align-self-center px-0">
                <a class="navbar-brand rounded ponteiro avatar-shadow px-1" @click="$router.push('/home')">
                    <!-- <img src="../../img/simboloes.png" alt="" height="35"> -->
                    <img v-if="imagemSrc" :src="'data:' + tipoImg + ';base64,' + imagemSrc" height="35"/>
                    <span v-else class="material-icons">business</span>
                </a>
            </div>
            <div class="col-auto align-self-center px-0">
                <div @click="logOut()" class="avatar avatar-40 text-white rounded ponteiro turnoff">
                    <span class="material-icons" style="font-size: 1.6rem;">power_settings_new</span>
                </div>
            </div>
            <div class="col-auto align-self-center">
                <button type="button" class="btn btn-link btn-40 colorsettings phone" :href="'tel:' + dadoshotel.celular" >
                    <span class="material-icons">perm_phone_msg</span>
                </button>
            </div>
            <div class="col-auto align-self-center">
                <div class="btn btn-link btn-40 colorsettings notification phone" @click="$router.push('/notificacoes')">
                    <span class="material-icons">notifications_none</span>
                    <span v-if="this.$store.getters.GetNotificacoes && this.$store.getters.GetNotificacoes.length>0" 
                    class="badge"
                    >
                        {{this.$store.getters.GetNotificacoes.length}}
                    </span>
                </div>
            </div>
            <div class="col-auto align-self-center">
                <div @click="loadPerfil()" class="avatar avatar-30 shadow-sm rounded-circle ml-2 ponteiro avatar-shadow">
                    <figure class="m-0 background">
                        <img id="imgHeader" width="30" height="30" :src="avatar" alt="">
                    </figure>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            avatar: CONSTANTS.API_URL + (this.$store.getters.GetLoginInfo.avatar ? 
                this.$store.getters.GetLoginInfo.avatar : '/img/perfil/P0.png'
            ),
            dadoshotel: [],
            imagemSrc: null,
            tipoImg: null,
            //intervalId: 0
        }
    },
    methods: {
        loadPerfil() {
            this.$router.push('/perfil');
        },
        puxarDados() {
            this.dadoshotel.push(this.$store.getters.GetLoginInfo.hotel)
        },
        logOut() {
            // liberar memória da sessão (persistedstate)
            sessionStorage.clear();
            this.$router.push('/');
        },
		carregarImagem(idimagem) {
            if (idimagem) {
                this.imagemSrc = null
                this.tipoImg = null
                axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: idimagem }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this.imagemSrc = Buffer.from(imgObj.dados).toString()
                            this.tipoImg = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},
        loadNotificacoes() {
            let params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                para: this.$store.getters.GetLoginInfo.idpessoa,
                tipo: 'H',
                whereRaw: 'notificacao.datavisualizacao is null',
                idioma: this.$i18n.locale
            };
            //console.error(JSON.stringify(params));
            axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', params, {headers: this.headers})
            .then(response => {
                if (response.status == 200) {
                    if (response.data) {
                        this.$store.dispatch('set_Notificacoes', response.data);
                        var obj = document.getElementById('pulse')
                        if (obj) {
                            if (response.data.length == 0) {
                                if (obj.classList.contains('pulse-css')) {
                                    obj.classList.remove('pulse-css')
                                }
                            } else {
                                if (!obj.classList.contains('pulse-css')) {
                                    obj.classList.add('pulse-css')
                                }
                            }
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(JSON.stringify(err))
            });
        },
        connectSocket: function() {
            this.$socket.disconnect();
            
            this.$socket.io.opts.query.email = this.$store.getters.GetLoginInfo.email;
            
            this.$socket.connect();

            this.sockets.subscribe("NOTAHOSPEDE", () => {
                this.loadNotificacoes()
            });

        }
    },
    mounted() {
        this.carregarImagem(this.$store.getters.GetLoginInfo.imagem)
        var b = document.getElementById('menu-btn-id');
        b.onclick = function () {
            var by = document.getElementById('menu-principal');
            if (by) {
                if (by.classList.contains('menu-overlay')) {
                    by.classList.add('menu-open');
                } else {
                    by.classList.add('menu-active');
                    by.classList.add('menu-open');
                }
            }
            return false;                
        };
        this.puxarDados();
        this.connectSocket();
        this.loadNotificacoes();
    },
    created() {
        // this.intervalId = window.setInterval(() => {
        //     this.loadNotificacoes();
        // }, 15000);
    },
    beforeDestroy(){
        this.sockets.unsubscribe("NOTAHOSPEDE");
    }

}
</script>

<style>
  .notification {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12pt;
  }

  /* .notification:hover {
    background: lightslategrey;
  } */

  .notification .badge {
    position: absolute;
    top: -3px;
    right: -5px;
    padding: 5px 7px;
    border-radius: 50%;
    background-color: red;
    color: white;
  }
  .turnoff {
      color: rgb(255, 255, 255)!important;
  }
  .turnoff:hover {
    font-size: 1.6rem!important;;
    color: navy!important;;
    /* text-shadow: 5px 5px 5px red, 5px 5px 5px white, 5px 5px 5px pink; */
    /* box-shadow: 2px 2px 5px black, 5px 5px 5px navy; */
  }
  .linha {
      border-bottom-color: rgb(89, 182, 219);
      border-bottom-style: solid;
      padding-bottom: 5px;
  }
  .avatar-shadow {
    max-width: 250px;
  }
  .avatar-shadow:hover {
    box-shadow: 2px 2px 5px black, 5px 5px 5px rgb(21, 21, 92)!important;
  }
  .phone:hover {
      color: navy!important;;
  }
</style>