<template>
  <div>
    <!-- Screen Loader -->
    <Loader />
    <!-- Begin page content -->
    <main class="flex-shrink-0 main has-footer bg-login">
        <!-- Fixed navbar -->
        <!-- <header class="header">
            <div class="row">
                
                <div class="text-left col align-self-center">
                   
                </div>
                <div class="ml-auto col-auto align-self-center">
                    <a href="#" class="text-white">
                        Cadastrar Conta
                    </a>
                </div>
            </div>
        </header> -->
        <div class="container h-100 text-white">
            <div class="row h-100">
                <div class="col-12 align-self-center mb-4">
                    <div class="row justify-content-center">
                        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 text-center">
							<!-- <img src="../../img/logo-ficha-guest.png" width="220" /> -->
							<img v-if="this.imagem_login_hospede"
                            :src="'data:' + this.imagem_login_hospede_tipo + ';base64,' + this.imagem_login_hospede"
                             width="220" />
                             <img v-else src="../../img/logo-ficha-guest.png" width="220" />

                            <h3 class="font-weight-normal mb-5">{{$t('loginde')}} {{$t('acesso')}}</h3>
                            <div class="form-group">
                                <label for="user_input">{{$t('emaildohospede')}}</label>
                                <input id="user_input" @keyup="updateMsg()" 
                                    type="text" autocomplete="username" 
                                    class="form-control" value="" v-model="user">
                            </div>
                            <div class="form-group">
                                <label for="user_pass">{{$t('apartamento')}}</label>
                                <input @keyup="updateMsg()" id="user_pass"
                                    className="loginGroup" type="password" 
                                    autocomplete="current-password" 
                                    class="form-control" value="" v-model="pass">
                            </div> 
                            <div class="form-group float-label position-relative">
                                <!-- Termo de Serviço -->
                                <label for="termo-servico-input">Li e concordo com os termos do serviço.</label>
                                <input class="mx-2" id="termo-servico-input" type="checkbox" 
                                    value="false" title="Li e concordo com os termos do serviço."
                                    v-model="aceiteTermo">
                                <div class="es-link ponteiro mb-2" @click="toggleDoc('termoservico')">Ver termos do serviço.</div>
                                <!-- Termo da LGPD -->
                                <label for="termo-lgpd-input">Li e concordo com os termos da LGPD.</label>
                                <input class="mx-2" id="termo-lgpd-input" type="checkbox" 
                                    value="false" title="Li e concordo com os termos da LGPD."
                                    v-model="aceiteLGPD">
                                <div class="es-link ponteiro" @click="toggleDoc('termolgpd')">Ver termo LGPD.</div>
                            </div>
                            <div class="form-group float-label position-relative">
                                <button id="login_button" @click="callLogin()" class="btn btn-default rounded btn-block">Acessar</button>
                            </div>
                            <div id="Msg_Verifique" class="text-center py-2"
                                style="opacity: 0; color: red; background-color: white; margin-top: 10px;border-radius:1.25rem;">
                                <p>{{$t('verifique')}}</p>
                            </div>                            
                        </div>
                    </div>
                </div>
                <template>
                    <div id="dialog-doc" class="es-dialog es-center">
                        <div class="es-dialog-container">
                            <div class="es-header">{{titulo}}</div>
                                <div v-if="!imgSrc" class="es-carregando">{{noDataText}}</div>
                                <template v-if="imgSrc">
                                    <div style="width:100%!important;height:100%!important;overflow-y:auto;">
                                        <!-- <iframe :width="pdfWidth" :height="pdfHeight"
                                        :src="imgSrc"></iframe> -->
                                        <pdf :src="imgSrc" 
                                            :page="pagina" 
                                            @num-pages="totalPaginas = $event">
                                        </pdf>
                                    </div>
                                </template>
                            <div>
                                <button :disabled="pagina==1" class="btn btn-primary" @click="pagina>1?pagina--:pagina">Anterior</button>
                                <div class="btn btn-primary mx-2" @click="toggleDoc">Fechar</div>
                                <button :disabled="pagina==totalPaginas" class="btn btn-primary" @click="pagina<totalPaginas?pagina++:pagina">Próxima</button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </main>

    <!-- footer-->
    <!-- <div class="footer no-bg-shadow py-3">
        <div class="row justify-content-center">
            <div class="col">
                <a href="index.html" class="btn btn-primary rounded btn-block">{{$t('acessarcomfacebook')}}</a>
            </div>
        </div>
    </div> -->
</div>

</template>

<script>
import axios from 'axios'
import Loader from '../components/Loader.vue'
import CONSTANTS from '../constants'
import pdf from 'vue-pdf'

export default {
    components: { Loader, pdf },
    data() {
        return {
            user: '',
            pass: '',
            idtipoavaliacao: (this.$route.params.id ? this.$route.params.id : 0),
            flag: (this.$route.params.flag ? this.$route.params.flag : 0),
            aceiteTermo: false,
            aceiteLGPD: false,
            titulo: 'Termos do Serviço',
			imgSrc: null,
			noDataText: 'Carregando documento...',
            hoteis: [],
            pdfHeight: window.innerHeight*0.7,
            pdfWidth: window.innerWidth*0.7,
            pagina: 1,
            totalPaginas: 0,
            imagem_login_hospede:null,
            imagem_login_hospede_tipo:null,
        }
    },
    methods: {
        toggleDoc(campo){
            this.pagina = 1
            if (campo == 'termoservico') {
                this.titulo = 'Termo de Serviço'
            } else {
                this.titulo = 'Termo da LGPD'
            }
            var docDialog = document.getElementById('dialog-doc')
            if (docDialog) {
                if (docDialog.style.display == 'none') {
                    docDialog.style.display = 'flex'
                    this.carregarImagem(campo)
                } else {
                    docDialog.style.display = 'none'
                }
            }
        },
        setMenuItens(data) {
            //console.error(data.menu)
            this.$store.dispatch('set_Itens', data.menu);
            var primeirosItens = []
            var demaisItens = [];
            var count = 0;
            for (var i=0; i < data.menu.length; i++) {
                var obj = data.menu[i]
                if (obj.telainicial) {
                    if (count < 6) {
                        primeirosItens.push(obj);
                    } else {
                        demaisItens.push(obj);
                    }
                    count++;
                }
            }
            this.$store.dispatch('set_Primeiros_Itens', primeirosItens);
            this.$store.dispatch('set_Demais_Itens', demaisItens);
        },        
        carregarUltimasTransacoes(theToken) {
            var data = {
                idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                idusuario : this.$store.getters.GetLoginInfo.idhotel,
                numreserva : this.$store.getters.GetLoginInfo.numreserva,
                idhospedepms : this.$store.getters.GetLoginInfo.idhospedepms,
                opcao : "todas",
                idioma: this.$i18n.locale
            };
            //console.log(JSON.stringify(data))
            axios.post(CONSTANTS.API_URL + '/fdguest/transacao/select', data,  {headers: {"x-access-token": theToken}})
            .then(response => {
                if (response.status == 200) {
                    this.$store.dispatch('set_Ultimas_Transacoes', response.data.slice(0,2));
                }
            }).catch((err) => {
                console.log({ err_name: err.name, err_message: err.message });
            });
        },
        loadNotificacoes(theToken) {
			axios.post(CONSTANTS.API_URL + '/fdguest/notificacao/select', {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    para: this.$store.getters.GetLoginInfo.idpessoa,
                    tipo: 'H',
                    whereRaw: 'notificacao.datavisualizacao is null',
                    idioma: this.$i18n.locale
				}, {headers: {"x-access-token": theToken}})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.$store.dispatch('set_Notificacoes', response.data);
                        var obj = document.getElementById('pulse')
                        if (obj) {
                            if (response.data.length == 0) {
                                if (obj.classList.contains('pulse-css')) {
                                    obj.classList.remove('pulse-css')
                                }
                            } else {
                                if (!obj.classList.contains('pulse-css')) {
                                    obj.classList.add('pulse-css')
                                }
                            }
                        }
					}
				} else {
                    console.log(JSON.stringify(response));
				}
			})
			.catch((err) => {
                console.log({ err_name: err.name, err_message: err.message });
			});
        },
        unfade(element) {
            var op = 0.1;  // initial opacity
            element.style.display = 'block';
            var timer = setInterval(function () {
                if (op >= 1){
                    clearInterval(timer);
                    element.focus();
                }
                element.style.opacity = op;
                element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                op += op * 0.085;
            }, 40);
        },        
        async callLogin() {
            console.error(`CONSTANTS.API_URL:${CONSTANTS.API_URL}`)
            if (!(this.aceiteTermo && this.aceiteLGPD)) {
                this.$snackW('É preciso marcar os checkboxes!')
            } else {
                axios.post(CONSTANTS.API_URL + '/hospedes/login', {
                    "email" : this.user,
                    "senha" : this.pass
                })
                .then(response => {
                    try {
                        if (response.status == 200) {
                            if (response.data) {
                                this.$store.dispatch('set_Login_Info', response.data);
                                this.$store.dispatch('set_Default_Language', response.data.idioma.codidioma);
                                this.$i18n.locale = response.data.idioma.codidioma;
                                this.carregarUltimasTransacoes(response.data.token);
                                this.setMenuItens(response.data)
                                console.error(`this.$store.getters.GetItens.length: ${this.$store.getters.GetItens.length}`)
                                this.loadNotificacoes(response.data.token)
                                if (this.$store.getters.GetAvaliacao > 0) {
                                    this.$router.push('/resposta')
                                } else {
                                    // if (this.$store.getters.GetItens
                                    //     && this.$store.getters.GetItens.length > 0) {
                                        this.$router.push('/home');
                                    // } else {
                                    //     console.error({
                                    //         Erro: 'Menu não configurado!',
                                    //         Mensagem: 'Verificar configuração do menu para o hotel ' + this.$store.getters.GetLoginInfo.idhotel
                                    //     })                                    
                                    // }
                                }
                            }
                        } else {
                            console.log(JSON.stringify(response))
                        }
                    }
                    catch(err) {
                        console.log({ err_name: err.name, err_message: err.message });
                    }
                })
                .catch((err) => {
                    console.log({ err_name: err.name, err_message: err.message });
                    if (err.message.includes('401')) {
                        const el = document.getElementById('Msg_Verifique');
                        if (el) {
                            this.unfade(el)
                            const ui = document.getElementById('user_input');
                            if (ui) {
                                ui.focus()
                            }
                        }
                    }
                });
            }
        },
        updateMsg() {
          const el = document.getElementById('Msg_Verifique');
          if (el) {
            if (el.style.opacity > 0) {
              el.style.opacity = 0
            }
          }
        },
        findId(campo) {
            var result = 0
            for (var i = 0; i < this.hoteis.length; i++) {
                var termoId = this.hoteis[i][campo]
                if (termoId) {
                    result = termoId
                }
            }
            return result;
        },
		carregarImagem(campo) {
            this.imgSrc = null
            this.noDataText = 'Carregando documento...'
			axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', {
                id: this.findId(campo),
                token: '3c8478c895580214e0ff389448054854'
            }, {headers: this.headers})
			.then((response) => {
				if (response.status == 200) {
					var imgObj = response.data[0]
					if (imgObj) {
                        this.imgSrc = Buffer.from(imgObj.dados.data).toString()
					} else {
                        this.noDataText = 'Nenhum arquivo, clique em Escolher Arquivo.'
					}
				}
			})
			.catch((e) => {
				console.error(e);
			});
		},
        carregarHoteis() {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotel/select/simples', {
                token: '3c8478c895580214e0ff389448054854'
            }).then(async (response) => {
				if (response.status == 200) {
					this.hoteis = response.data;
                    console.log('this.hoteis ',this.hoteis)
                   
                    if(this.hoteis[0].imagem_login_hospede){
                        axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select',{id:this.hoteis[0].imagem_login_hospede})
                        .then(resp => {
                            console.log('conseguiu ler a imagem')
                            console.log('resp.data ',resp.data)
                            this.imagem_login_hospede_tipo = resp.data[0].tipo
                            this.imagem_login_hospede = Buffer.from(resp.data[0].dados).toString()

                            console.log('this.imagem_login_hospede ',this.imagem_login_hospede)
                        }).catch(error =>{
                            this.imagem_login_hospede = null
                            console.log('erro ao ler a imagem')
                            console.log(error)
                        })
                    }else{
                        this.imagem_login_hospede = null
                    }
				}
			})
			.catch((e) => {
				console.error(e);
			});
        }
    },
    mounted() {
        // liberar memória da sessão (persistedstate)
        sessionStorage.clear();
        // Carregar lista de hoteis
        this.carregarHoteis()
        // Se houver idtipoavaliacao na url, chama a rota para responder a avaliação
        if (this.idtipoavaliacao > 0) {
            this.$store.dispatch('set_Avaliacao', this.idtipoavaliacao);
            if (this.flag && this.$store.getters.GetLoginInfo) {
                this.$router.push('/resposta')
            }
        }
        const el = document.getElementById('login_button')
        if (el) {
            el.focus()
        }
    }
}
</script>

<style  scoped>
main {
    background-image: url(../../img/background-ficha-guest.png); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
}
.es-center {
  margin: auto;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  border: 3px solid rgb(45, 66, 107);
  border-radius: 0.7rem;
  padding: 10px;
}
.es-dialog {
    display: none;
    position: absolute;
    background-color: white;
    z-index: 1000;
}
.es-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.es-link {
    color: rgb(0, 180, 255);
}
.es-link:hover {
    color: rgb(190, 232, 250);
    /* font-weight: bold; */
}
.es-carregando {
	padding: 3rem;
	padding-top: 7rem;
	padding-bottom: 7rem;
	color: orange;
	font-weight: bold;
}
.es-header {
    padding: 0.8rem;
    text-align: center;
    width: 100%;
    border-radius: 0.7rem;
    background-color: rgb(37, 100, 212);
    font-size: 1.2rem;
}
.es-iframe {
    width: 900px;
    height: 400px;
    max-width: 900px;
    max-height: 400px;
}
.es-email-login {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.es-email-login:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.es-email-label {
  opacity: 0.5;
  position: absolute;
  left: 20px;
  top: 22px;
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  font-size: 15px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  -moz-transition: ease all 0.5s;
  z-index: 0;    
}
.es-email-label:focus {
     opacity: 1;
  top: 0;
  font-size: 11px;
  line-height: 16px;
}
</style>

