<template>
	<div>
		<Loader />
		<Menu />
		<div class="backdrop"></div>
		<main class="flex-shrink-0 main has-footer">
			<Header />
			<div class="container mb-1 mt-3 text-center text-white">
			<h5 class="my-3 es-titulo"><span class="material-icons icon my-3 es-titulo">apartment</span> {{$t('sobrehotel')}}</h5>
			<h6>{{$t('sobretitulo')}}</h6>	
		</div>
		<div class="main-container mt-4">
			<!-- Ítens Sobre o Hotel -->
			<div class="container mb-4" v-for="(item,index) in hotelSobreItens" :key="index">
				<div class="card bg-info">
					<div class="card-body">
						<div class="media text-white">
							<div style="width:100%;">
								<h5 class="font-weight-normal">{{item.titulo}}</h5>
								<p><small class="text-mute">{{item.subtitulo}}</small></p>
								<textarea style="width:100%;padding:0.5rem;border-radius:15px;" 
									v-model="item.texto" readonly></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Site do Hotel -->
			<div class="container mb-4">
				<div class="card bg-default">
					<div class="card-body">
						<div class="ponteiro" @click="openSite()">
							<div class="media text-white">
								<div class="icon icon-50 bg-white text-default mr-2 rounded-circle"><i class="material-icons">public</i></div>
								<div class="media-inner">
									<h5 class="font-weight-normal">{{$t('website')}}</h5>
									<p><small class="text-mute">{{$t('conhecahotel')}}</small></p>
								</div>
								<div class="align-self-center ml-auto">
									<i class="material-icons">arrow_forward</i>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</div>
			<!-- Sobre a cidade -->
			<div class="container mb-4">
				<div class="card bg-info">
					<div class="card-body">
						<div class="media ponteiro" @click="$router.push('/cidade')">
							<div class="icon icon-50 bg-white text-info mr-2 rounded-circle"><i class="material-icons">location_city</i></div>
							<div class="media-inner">
								<h5 class="font-weight-normal">{{$t('cidade')}}</h5>
								<p><small class="text-mute">{{$t('conhecacidade')}}</small></p>
							</div>
							<div class="align-self-center ml-auto">
								<i class="material-icons">arrow_forward</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- GMaps -->
			<div class="container mb-4">
				<div class="card bg-default">
					<div class="card-body">
						<div class="media ponteiro" @click="openMap()">
							<div class="icon icon-50 bg-white text-info mr-2 rounded-circle"><i class="material-icons">where_to_vote</i></div>
							<div class="media-inner">
								<h5 class="font-weight-normal">GMaps</h5>
								<p><small class="text-mute">{{$t('conhecacidade')}}</small></p>
							</div>
							<div class="align-self-center ml-auto">
								<i class="material-icons">arrow_forward</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</main>
		<Footer />
	</div>
</template>

<script>
import Loader from '../components/Loader.vue'
import Menu from '../components/Menu.vue'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    components: {
         Loader, Menu, Header, Footer
    },
    data() {
        return {
            headers: {
				'x-access-token': this.$store.getters.GetLoginInfo.token
			},
			hotelSobreItens: [],
			site: '',
			nome: ''
        }
    },
	methods: {
		carregarHotelSobreItens() {
            var params = {
				idhotel : this.$store.getters.GetLoginInfo.idhotel,
				idioma: this.$i18n.locale,
				ativo: true
			}; 
            console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelsobre/select', params,  {headers: this.headers})
                .then(response => {
                if (response.status == 200) {
                    this.hotelSobreItens = response.data
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });

		},
		openMap() {
			let nomeHotel = this.nome.trim();
			if (nomeHotel) {
				var url = new String("https://maps.google.com/maps?q="
					+ nomeHotel
					+ "&amp;hl=pt-br;z=16")
				window.open(url,'')
			}
		},
		openSite() {
			let siteHotel = this.site.trim();
			if (siteHotel) {
				var url = new String(siteHotel)
				if (url.substring(0,4) != 'http') {
					url = 'http://' + url
				}
				window.open(url,'')
			}
		},
		carregarDadosHotel() {
			var params = {
				idhotel : this.$store.getters.GetLoginInfo.idhotel
			}; 
            console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotel/select', params,  {headers: this.headers})
                .then(response => {
                if (response.status == 200) {
					if (response.data.length > 0) {
						this.site = response.data[0].site;
						this.nome = response.data[0].nome;
					}
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        }
	},

	mounted() {
		this.carregarDadosHotel();
		this.carregarHotelSobreItens();
	}

}
</script>

<style>

</style>